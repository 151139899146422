import React from "react"
import Layout from "../components/layout/layout"
import SearchModule  from "../components/search2/searchModule"
import SEO from "../components/seo"



const Search = ( ) => {


  return (
    <Layout >
    <SEO title="Search" 
    noIndex={true}
    />
     <SearchModule showManufacturer={true} />
    </Layout>
  )
}

export default Search
